import {
  getTaxConfig,
  getTicketServiceFee,
  getTicketTax,
  isTaxAdded,
  getTaxRateLabel,
  isDonationTicketDefinition,
  hasPricingOptions,
  GetFormattedMoney,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {isWixFeeTypeIsAdded} from './tickets'

interface TaxProps {
  event: ExtendedEvent
  ticket: wix.events.ticketing.TicketDefinition
  t: TFunction
  overridePrice?: string
  abstract?: boolean
  getFormattedMoney: GetFormattedMoney
}

export const getTax = ({event, ticket, t, overridePrice, abstract = false, getFormattedMoney}: TaxProps) => {
  const taxConfig = getTaxConfig(event)
  const isDonation = isDonationTicketDefinition(ticket)
  const visible = Boolean(taxConfig) && !ticket.free && (!isDonation || taxConfig.appliesToDonations)

  if (!visible) {
    return null
  }

  if (!isTaxAdded(taxConfig.type)) {
    return t('tax.included', taxConfig)
  } else if (abstract) {
    return `+${getTaxRateLabel(taxConfig)} ${taxConfig.name}`
  } else {
    return `+${getTicketTax(ticket, taxConfig, overridePrice, getFormattedMoney)} ${taxConfig.name}`
  }
}

export const getFee = ({event, ticket, t, overridePrice, abstract = false, getFormattedMoney}: TaxProps) => {
  const taxConfig = getTaxConfig(event)

  if (!isWixFeeTypeIsAdded(ticket) || ticket.free) {
    return null
  }

  if (abstract) {
    return `+${t('checkout_serviceFee')}`
  } else {
    return t('checkout_plusServiceFee', {fee: getTicketServiceFee(ticket, taxConfig, overridePrice, getFormattedMoney)})
  }
}

export const getFees = (
  event: ExtendedEvent,
  ticket: wix.events.ticketing.TicketDefinition,
  t: TFunction,
  getFormattedMoney: GetFormattedMoney,
  overridePrice?: string,
) => {
  const lacksPriceOverride = (isDonationTicketDefinition(ticket) || hasPricingOptions(ticket)) && !overridePrice
  if (lacksPriceOverride) {
    return getAbstractFees(event, ticket, t, getFormattedMoney)
  }
  return [
    getTax({event, ticket, t, overridePrice, getFormattedMoney}),
    getFee({event, ticket, t, overridePrice, getFormattedMoney}),
  ]
    .filter(Boolean)
    .join(', ')
}

export const getAbstractFees = (
  event: ExtendedEvent,
  ticket: wix.events.ticketing.TicketDefinition,
  t: TFunction,
  getFormattedMoney: GetFormattedMoney,
) => {
  return [
    getTax({event, ticket, t, abstract: true, getFormattedMoney}),
    getFee({event, ticket, t, abstract: true, getFormattedMoney}),
  ]
    .filter(Boolean)
    .join(', ')
}
